<template>
    <painel :titulo="`Consultorias - ${operacao}`" icone="handshake" :mostrarRefresh="operacao == 'ATUALIZAR'" :refreshFunction="obterConsultoriaAtualizada">
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-3 lg:col-3 xl:col-2">
                <label class="required">CPF do Cliente</label>
                <InputMask v-model="cpf" mask="999.999.999-99" :unmask="true" @keypress="v$.cpf.$touch()" />
                <small class="p-error" v-if="(v$.cpf.required.$invalid || v$.cpf.minLength.$invalid) && v$.cpf.$error">O CPF é obrigatório</small>
                <small class="p-error" v-if="v$.cpf.cpfValido.$invalid && !(v$.cpf.required.$invalid || v$.cpf.minLength.$invalid)">CPF inválido</small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3 xl:col-2">
                <label class="required">Tipo Cliente</label>
                <Dropdown v-model="tipoEmpresa" :options="tiposEmpresas" optionLabel="descricao" optionValue="id"></Dropdown>
            </div>
            <div class="field col-12 md:col-3 lg:col-3 xl:col-2">
                <label class="required">Código do Cliente</label>
                <InputText v-model="codigoCliente" @input="v$.codigoCliente.$touch()" maxlength="50" />
                <small class="p-error" v-if="v$.codigoCliente.required.$invalid && v$.codigoCliente.$error">O código do cliente é obrigatório</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3 xl:col-2">
                <label class="required">Início Atendimento</label>
                <InputMask v-model="inicioAtendimento" mask="99/99/9999 99:99" @keypress="v$.inicioAtendimento.$touch()" />
                <small class="p-error" v-if="v$.inicioAtendimento.required.$invalid && v$.inicioAtendimento.$error"
                    >O início do atendimento é obrigatório.
                </small>
                <small class="p-error" v-if="v$.inicioAtendimento.dataValida.$invalid && !v$.inicioAtendimento.required.$invalid">Data inválida. </small>
                <small
                    class="p-error"
                    v-if="v$.inicioAtendimento.dataValida2.$invalid && !v$.inicioAtendimento.required.$invalid && !v$.inicioAtendimento.dataValida.$invalid"
                    >O ano deve ser igual ou superior a 1890.
                </small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3 xl:col-2">
                <label class="required">Final Atendimento</label>
                <InputMask v-model="finalAtendimento" mask="99/99/9999 99:99" @keypress="v$.finalAtendimento.$touch()" />
                <small class="p-error" v-if="v$.finalAtendimento.required.$invalid && v$.finalAtendimento.$error">O final do atendimento é obrigatório. </small>
                <small class="p-error" v-if="v$.finalAtendimento.dataValida.$invalid && !v$.finalAtendimento.required.$invalid">Data inválida. </small>
                <small
                    class="p-error"
                    v-if="v$.finalAtendimento.dataValida2.$invalid && !v$.finalAtendimento.required.$invalid && !v$.finalAtendimento.dataValida.$invalid"
                    >Final do atendimento deve ser maior que início do atendimento.
                </small>
                <small
                    class="p-error"
                    v-if="
                        v$.finalAtendimento.dataValida3.$invalid &&
                        !v$.finalAtendimento.required.$invalid &&
                        !v$.finalAtendimento.dataValida.$invalid &&
                        !v$.finalAtendimento.dataValida2.$invalid
                    "
                    >O ano deve ser igual ou superior a 1890.
                </small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3 xl:col-2">
                <label class="required">Tipo</label>
                <InputMask v-model="tipo" mask="aaa" :unmask="true" @keypress="v$.tipo.$touch()" disabled />
                <small class="p-error" v-if="v$.tipo.$error">O tipo do atendimento é obrigatório</small>
            </div>
            <div class="field col-12 md:col-3 lg:col-3 xl:col-2">
                <label class="required">Abordagem</label>
                <Dropdown v-model="abordagem" :options="abordagens" optionLabel="descricao" optionValue="codigo"></Dropdown>
            </div>
            <div class="field col-12 md:col-3 lg:col-3 xl:col-2">
                <label class="required">CPF do Gestor Responsável</label>
                <InputMask v-model="cpfResponsavel" mask="999.999.999-99" :unmask="true" @keypress="v$.cpfResponsavel.$touch()" />
                <small class="p-error" v-if="(v$.cpfResponsavel.required.$invalid || v$.cpfResponsavel.minLength.$invalid) && v$.cpfResponsavel.$error"
                    >O CPF é obrigatório</small
                >
                <small
                    class="p-error"
                    v-if="v$.cpfResponsavel.cpfValido.$invalid && !(v$.cpfResponsavel.required.$invalid || v$.cpfResponsavel.minLength.$invalid)"
                    >CPF inválido</small
                >
            </div>
            <div class="field col-12 md:col-3 lg:col-3 xl:col-2">
                <label class="required">Carga Horária</label>
                <InputMask v-model="cargaHoraria" mask="999:99" :unmask="false" @input="v$.cargaHoraria.$touch()" />
                <small class="p-error" v-if="v$.cargaHoraria.$error">A carga horária é obrigatória</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-12 xl:col-6">
                <label class="required">Título</label>
                <InputText type="text" v-model="titulo" @input="v$.titulo.$touch()" />
                <small class="p-error" v-if="v$.titulo.$error">O título do atendimento é obrigatório</small>
            </div>
            <div class="field col-12 md:col-6 xl:col-3">
                <label class="required">Projeto</label>
                <Dropdown
                    v-model="codProjeto"
                    :options="projetos"
                    optionLabel="nomePratif"
                    optionValue="codPratif"
                    :filter="true"
                    placeholder="Selecione o projeto"
                    :showClear="true"
                    :filterFields="['nomePratif']"
                ></Dropdown>
            </div>
            <div class="field col-12 md:col-6 xl:col-3">
                <label class="required">Ação</label>
                <Dropdown
                    v-model="codAcao"
                    :options="acoesProjeto"
                    optionLabel="nomeAcao"
                    optionValue="codAcaoSeq"
                    :filter="true"
                    placeholder="Selecione a ação"
                    :showClear="true"
                    :filterFields="['nomeAcao']"
                ></Dropdown>
            </div>
            <div class="field col-12 md:col-6 xl:col-3">
                <label class="required">Instrumento</label>
                <Dropdown
                    v-model="codInstrumento"
                    :options="instrumentos"
                    optionLabel="descCategoria"
                    optionValue="codCategoria"
                    :filter="true"
                    placeholder="Selecione o instrumento"
                    :showClear="true"
                    :filterFields="['descCategoria']"
                ></Dropdown>
            </div>
            <div class="field col-12 md:col-6 xl:col-3">
                <label class="required">Tema</label>
                <Dropdown
                    v-model="codTema"
                    :options="temas"
                    optionLabel="temaSuperiorAreaTematica"
                    optionValue="codAreaTematica"
                    :filter="true"
                    placeholder="Selecione o tema"
                    :showClear="true"
                    :filterFields="['temaSuperiorAreaTematica']"
                ></Dropdown>
            </div>
            <div class="field col-12">
                <label class="required">Descrição do Atendimento</label>
                <Textarea v-model="descricao" :autoResize="true" rows="7" @input="v$.descricao.$touch()" />
                <small class="p-error" v-if="v$.descricao.$error">A descrição do atendimento é obrigatória</small>
            </div>
        </div>
        <slot name="botoes"></slot>
        <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar()" />
        <Button label="Salvar" icon="pi pi-check-circle" class="ml-2 p-button-primary" @click="confirmarSalvar()" :disabled="v$.$invalid" />
    </painel>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';

export default {
    props: {
        operacao: {
            type: String,
        },

        consultoria: {
            type: Object,
        },

        services: {
            type: Object,
        },

        cancelar: {
            type: Function,
        },

        erros: {
            type: Array,
        },
    },

    setup() {
        return { v$: useVuelidate() };
    },

    emits: ['salvar', 'obterConsultoriaAtualizada'],

    data() {
        return {
            cpf: null,
            codigoCliente: null,
            tipoEmpresa: 'CNPJ',
            inicioAtendimento: null,
            finalAtendimento: null,
            titulo: null,
            tipo: 'CON',
            abordagem: 'I',
            descricao: null,
            projetos: [],
            codProjeto: null,
            acoes: [],
            codAcao: null,
            codInstrumento: null,
            instrumentos: [],
            temas: [],
            codTema: null,
            cargaHoraria: null,
            cpfResponsavel: null,
            acaoInicial: true,
            abordagens: [
                { codigo: 'I', descricao: 'INDIVIDUAL' },
                { codigo: 'G', descricao: 'GRUPAL' },
                { codigo: 'U', descricao: 'UNIVERSAL' },
            ],
            tiposEmpresas: [],
        };
    },

    validations() {
        return {
            cpf: {
                required,
                minLength: minLength(11),
                cpfValido: () => {
                    return this.$validarCPF(this.cpf);
                },
            },
            tipoEmpresa: { required },
            codigoCliente: { required },
            inicioAtendimento: {
                required,
                dataValida: () => {
                    let dataInicio = this.$moment(`${this.inicioAtendimento}:00`, 'DD/MM/YYYY HH:mm:ss', true);
                    return dataInicio.isValid();
                },
                dataValida2: () => {
                    let dataInicio = this.$moment(`${this.inicioAtendimento}:00`, 'DD/MM/YYYY HH:mm:ss', true);
                    return dataInicio.year() > '1889';
                },
            },
            finalAtendimento: {
                required,
                dataValida: () => {
                    let dataFinal = this.$moment(`${this.finalAtendimento}:00`, 'DD/MM/YYYY HH:mm:ss', true);
                    return dataFinal.isValid();
                },
                dataValida2: () => {
                    let dataInicio = this.$moment(`${this.inicioAtendimento}:00`, 'DD/MM/YYYY HH:mm:ss', true);
                    let dataFinal = this.$moment(`${this.finalAtendimento}:00`, 'DD/MM/YYYY HH:mm:ss', true);
                    return dataFinal.isAfter(dataInicio);
                },
                dataValida3: () => {
                    let dataFinal = this.$moment(`${this.finalAtendimento}:00`, 'DD/MM/YYYY HH:mm:ss', true);
                    return dataFinal.year() > '1889';
                },
            },
            titulo: { required },
            tipo: { required },
            abordagem: { required },
            descricao: { required },
            codProjeto: { required },
            codAcao: { required },
            codInstrumento: { required },
            codTema: { required },
            cargaHoraria: { required },
            cpfResponsavel: {
                required,
                minLength: minLength(11),
                cpfValido: () => {
                    return this.$validarCPF(this.cpfResponsavel);
                },
            },
        };
    },

    methods: {
        obterOpcoes() {
            this.$store.dispatch('addRequest');
            this.services.obterOpcoes().then((response) => {
                if (response?.success) {
                    this.projetos = response.data.projetos;
                    this.acoes = response.data.acoes;
                    this.instrumentos = response.data.instrumentos;
                    this.temas = response.data.temas;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Consultorias',
                        detail: 'Falha ao obter opções de seleção',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterTiposEmpresas() {
            this.$store.dispatch('addRequest');
            this.services.obterTiposEmpresas().then((response) => {
                if (response?.success) {
                    this.tiposEmpresas = response.data;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Consultorias',
                        detail: 'Falha ao obter tipos de empresas',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        decimalToHours(decimal) {
            const horas = Math.floor(decimal / 60);
            const minuto = decimal % 60;
            const textoHoras = horas.toString().padStart(3, '0');
            const textoMinutos = minuto.toString().padStart(2, '0');
            return `${textoHoras}:${textoMinutos}`;
        },

        horaToMinutos(hora) {
            let f0 = hora.split(':');
            const min = Number(f0[0]) * 60 + Number(f0[1]);
            return min;
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja ${this.operacao == 'INSERIR' ? 'inserir' : 'atualizar'} a consultoria?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            let instrumento = this.instrumentos.filter((item) => {
                return item.codCategoria === this.codInstrumento;
            })[0];

            let projeto = this.projetos.filter((item) => {
                return item.codPratif === this.codProjeto;
            })[0];

            let acao = this.acoes.filter((item) => {
                return item.codAcaoSeq === this.codAcao;
            })[0];

            let tema = this.temas.filter((item) => {
                return item.codAreaTematica === this.codTema;
            })[0];

            let consultoriaDto = {
                cpf: this.cpf,
                codigoEmpresa: this.codigoCliente,
                tipoEmpresa: this.tipoEmpresa,
                inicioAtendimento: this.$moment(this.inicioAtendimento, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss'),
                finalAtendimento: this.$moment(this.finalAtendimento, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss'),
                tituloAtendimento: this.titulo,
                tipoAtendimento: this.tipo,
                abordagem: this.abordagem,
                descricao: this.descricao,
                codProjeto: this.codProjeto,
                projeto: projeto.nomePratif,
                codAcao: this.codAcao,
                acao: acao.nomeAcao,
                codInstrumento: this.codInstrumento,
                instrumento: instrumento.descCategoria,
                codTema: this.codTema,
                tema: tema.temaSuperiorAreaTematica,
                cargaHoraria: this.horaToMinutos(this.cargaHoraria),
                cpfResponsavel: this.cpfResponsavel,
            };
            this.$emit('salvar', consultoriaDto);
        },

        preencherForm() {
            this.cpf = this.consultoria.cpf;
            this.codigoCliente = this.consultoria.codigoEmpresa;
            this.tipoEmpresa = this.consultoria.tipoEmpresa;
            this.inicioAtendimento = this.$moment(this.consultoria.inicioAtendimento).format('DD/MM/YYYY HH:mm');
            this.finalAtendimento = this.$moment(this.consultoria.finalAtendimento).format('DD/MM/YYYY HH:mm');
            this.titulo = this.consultoria.tituloAtendimento;
            this.tipo = this.consultoria.tipoAtendimento;
            this.abordagem = this.consultoria.abordagem;
            this.descricao = this.consultoria.descricao;
            this.codProjeto = this.consultoria.codProjeto;
            this.codAcao = this.consultoria.codAcao;
            this.codInstrumento = this.consultoria.codInstrumento;
            this.codTema = this.consultoria.codTema;
            this.cargaHoraria = this.decimalToHours(this.consultoria.cargaHoraria);
            this.cpfResponsavel = this.consultoria.cpfResponsavel;
        },

        obterConsultoriaAtualizada() {
            this.$emit('obterConsultoriaAtualizada');
        },
    },

    created() {
        this.obterOpcoes();
        this.obterTiposEmpresas();
    },

    computed: {
        acoesProjeto() {
            return this.acoes.filter((item) => {
                return item.codPratif === this.codProjeto;
            });
        },
    },

    watch: {
        codProjeto() {
            if (this.operacao == 'INSERIR' || (this.operacao == 'ATUALIZAR' && !this.acaoInicial)) {
                this.codAcao = null;
            }
            if (this.operacao == 'ATUALIZAR' && this.acaoInicial) {
                this.codAcao = this.consultoria.codAcao;
                this.acaoInicial = false;
            }
        },

        consultoria() {
            if (this.operacao == 'ATUALIZAR' && this.consultoria) {
                this.preencherForm();
            }
        },
    },
};
</script>
