import axios from "axios";

import { errorHandler } from "@/shared/auth/errorHandler";

export const axiosBase = axios.create({
    headers: {
        "Content-Type": "application/json"
    }
});

axiosBase.interceptors.response.use(
    response => {
        return { success: true, data: response.data };
    },
    error => {
        return { success: false, errors: errorHandler(error) };
    }
);
