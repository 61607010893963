import { axiosJwt } from '@/shared/auth/axiosJwt';
import { axiosBase } from '@/shared/auth/axiosBase';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_UTILIDADES_PORT}${process.env.VUE_APP_API_PATH}/utilidades/consultorias`;
const urlBase = `${api}`;

export default {
    obterConsultorias() {
        return axiosJwt.get(`${urlBase}`);
    },

    obterOpcoes() {
        return axiosJwt.get(
            `${urlBase}/opcoes`
        );
    },

    obterTiposEmpresas() {
        return axiosBase.get('data/tipos_empresas.json');
    },

    inserir(consultoria) {
        return axiosJwt.post(`${urlBase}`, consultoria);
    },

    obterPorId(id) {
        return axiosJwt.get(`${urlBase}/${id}`);
    },

    atualizar(consultoria) {
        return axiosJwt.put(`${urlBase}`, consultoria);
    },

    excluir(consultoriaId) {
        return axiosJwt.delete(`${urlBase}/${consultoriaId}`);
    },
};
